.toast_message_container {
    padding: 6px 12px;
    color: var(--primary);
    border-radius: 4px;
    background: white;
    box-shadow: -9px 6px 20px 0px rgba(0, 0, 0, 0.08);
    width: fit-content;
    max-width: 75%;
}

.toast_message_success {
    background: #eefff7;
    border: 1px solid var(--green);
}

.toast_message_error {
    background: #ffe9ec;
    border: 1px solid var(--red);
}

.toast_message {
    width: fit-content;
    font-weight: 500;
    font-size: 10px;
}
